<template>
  <div class="container">
    <router-view v-if="this.$route.path == '/order/orderdec'"></router-view>
    <div class="mainbox" v-else>
      <div class="ordertitle">小程序订单列表</div>
      <div class="searchbox">
        <div class="searchitembox">
          <div class="searchitem">
            <div class="lebel">订单编号</div>
            <el-input placeholder="请输入订单编号" prefix-icon="el-icon-search" v-model="order_sn"></el-input>
          </div>
          <div class="searchitem">
            <div class="lebel">完成时间</div>
            <el-date-picker type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
              v-model="time">
            </el-date-picker>
          </div>
          <div class="searchitem">
            <div class="lebel">手机号码</div>
            <el-input placeholder="请输入下单人手机号" prefix-icon="el-icon-search" v-model="phone"></el-input>
          </div>
          <div class="searchitem">
            <div class="lebel">下单门店</div>
            <el-input placeholder="请输入下单门店" prefix-icon="el-icon-search" v-model="shop"></el-input>
          </div>
          <div class="searchitem">
            <div class="lebel">支付方式</div>
            <el-select placeholder="请选择支付方式" v-model="pay_type" clearable>
              <el-option :value="1" label="微信支付" />
              <el-option :value="2" label="余额支付" />
              <el-option :value="3" label="洗车卡抵扣" />
            </el-select>
          </div>
          <div class="searchitem">
            <div class="lebel">卡类型</div>
            <el-select placeholder="请选择卡类型" v-model="card_type">
              <el-option :value="1" label="年卡" />
              <el-option :value="2" label="次卡" />
              <el-option :value="3" label="抖音兑换" />
              <el-option :value="4" label="福利官年卡" />
              <el-option :value="5" label="美团兑换" />
            </el-select>
          </div>
        </div>
        <div class="searchbtns">
          <div class="search" @click="search">搜索</div>
          <div class="export" @click="clear">清除</div>
          <div class="export" @click="exportData">导出</div>
        </div>
      </div>
      <!-- 订单列表 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="order_sn" label="订单编号"> </el-table-column>
        <el-table-column prop="nickname" label="用户昵称" align="center">
        </el-table-column>
        <el-table-column prop="user_phone" label="手机号" align="center">
        </el-table-column>
        <el-table-column prop="type" label="服务类型" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.is_append_shuila == 0">快洗</div>
            <div v-if="scope.row.is_append_shuila == 1">精洗</div>
          </template>
        </el-table-column>
        <el-table-column prop="store_name" label="下单门店" align="center">
        </el-table-column>
        <el-table-column prop="actual_paid" label="实付金额(元)" align="center">
        </el-table-column>
        <el-table-column prop="store_profit" label="门店收益(元)" align="center">
        </el-table-column>
        <el-table-column prop="add_time" label="下单时间" align="center">
        </el-table-column>
        <el-table-column prop="pay_type" label="支付方式" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.actual_paid > 0 && scope.row.pay_type == 1">微信支付</div>
            <div v-if="scope.row.actual_paid > 0 && scope.row.pay_type == 2">余额支付</div>
            <div v-if="scope.row.actual_paid == 0">洗车卡抵扣</div>
          </template>
        </el-table-column>
        <el-table-column prop="card_type" label="卡类型" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.card_type == 1">年卡</div>
            <div v-if="scope.row.card_type == 2">次卡</div>
            <div v-if="scope.row.card_type == 3">抖音兑换</div>
            <div v-if="scope.row.card_type == 4">福利官年卡</div>
            <div v-if="scope.row.card_type == 5">美团兑换</div>
            <div v-if="!scope.row.card_type">未使用</div>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="操作" align="center">
          <template slot-scope="scope">
            <div class="orderdec" @click="toorderdec(scope.row.id)">查看</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { orderList } from "@/api/api";
import { formatDate } from "@/utils/filters";

export default {
  data() {
    return {
      p: 1,
      total: 0,
      tableData: [],
      order_sn: "",
      time: [],
      phone: "",
      shop: "",
      pay_type: null,
      card_type: null,
    };
  },
  created() {
    this.getorderList();
  },
  methods: {
    // 获取订单列表
    async getorderList() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await orderList({ admin_id, p: this.p });
        console.log(res);
        this.tableData = res.data.list;
        this.total = Number(res.data.count);
      } catch (error) {
        console.log(error);
      }
    },
    // 订单详情
    toorderdec(id) {
      this.$router.push({ path: "/order/orderdec", query: { id: id } });
    },
    clear() {
      this.order_sn = "";
      this.time = "";
      this.phone = "";
      this.shop = "";
      this.getorderList();
    },
    async search() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        if (this.time != "") {
          this.time[0] = formatDate(this.time[0]);
          this.time[1] = formatDate(this.time[1]);
        }
        const res = await orderList({
          admin_id,
          p: this.p,
          order_sn: this.order_sn,
          start_time: this.time[0],
          end_time: this.time[1],
          phone: this.phone,
          store_name: this.shop,
          pay_type: this.pay_type,
          card_type: this.card_type,
        });
        console.log(res);
        this.tableData = res.data.list;
        this.total = Number(res.data.count);
      } catch (error) {
        console.log(error);
      }
    },
    //分页
    handleCurrentChange(val) {
      this.p = val;
      this.search();
    },
    // 添加导出方法
    async exportData() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        let params = {
          admin_id,
          order_sn: this.order_sn,
          phone: this.phone,
          store_name: this.shop,
          pay_type: this.pay_type,
          card_type: this.card_type,
        };

        // 处理时间
        if (this.time && this.time.length) {
          params.start_time = formatDate(this.time[0]);
          params.end_time = formatDate(this.time[1]);
        }

        // 构建URL参数
        const queryString = Object.keys(params)
          .filter(key => params[key] !== undefined && params[key] !== null)
          .map(key => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');
        console.log("queryString", queryString);
        // 打开新窗口下载文件
        const url = this.$const.fileUrl + 'api/67693b5e02b1e?' + queryString;
        window.open(url);
        this.$message({
          type: "success",
          message: "导出成功！",
        });
      } catch (error) {
        console.log(error);
        this.$message.error('导出失败');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 90vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;

  .searchbox {
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
  }

  .ordertitle {
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }

  .searchitembox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .searchitem {
      display: flex;
      align-items: center;

      .lebel {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-right: 8px;
      }
    }
  }

  .searchbtns {
    display: flex;
    margin-top: 20px;
    //margin-left: 65px;

    // 共享样式的按钮基类
    %blue-button {
      width: 78px;
      height: 36px;
      background: #0073ff;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
    }

    .search {
      @extend %blue-button;
    }

    .export {
      @extend %blue-button;
      margin-left: 16px;
    }
  }

  .orderdec {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #2b6df9;
    cursor: pointer;
  }
}

::v-deep .el-input {
  width: 240px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}

::v-deep .el-table thead tr>th {
  background-color: #f5f5f5;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
}

::v-deep .el-table {
  margin-bottom: 50px;
}

::v-deep .el-pagination {
  position: absolute;
  bottom: 20px;
  right: 0;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
  background-color: #fff; // 进行修改未选中背景和字体// color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1890ff; // 进行修改选中项背景和字体color: #fff;
}
</style>
