<template>
  <div class="container">
    <router-view v-if="this.$route.path === '/porder/porderdec'"></router-view>
    <div class="mainbox" v-else>
      <div class="ordertitle">平台订单列表</div>
      <div class="searchbox">
        <div class="searchitembox">
          <div class="searchitem">
            <div class="lebel">订单号</div>
            <el-input placeholder="请输入订单号" prefix-icon="el-icon-search" v-model="orderNo"></el-input>
          </div>
          <div class="searchitem">
            <div class="lebel">创建时间</div>
            <el-date-picker type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
              value-format="timestamp" v-model="createTime" :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </div>
          <div class="searchitem">
            <div class="lebel">结束时间</div>
            <el-date-picker type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
              value-format="timestamp" v-model="endTime" :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </div>
          <div class="searchitem">
            <div class="lebel">站点</div>
            <el-select placeholder="请选择站点" v-model="deviceId" clearable>
              <el-option label="全部" :value="null" />
              <el-option v-for="item in shopOptions" :key="item.equipment_id" :label="item.store_name"
                :value="item.equipment_id" />
            </el-select>
          </div>
          <div class="searchitem">
            <div class="lebel">渠道</div>
            <el-select placeholder="请选择渠道" v-model="qCustomerCode" clearable>
              <el-option label="全部" :value="null" />
              <el-option v-for="item in customerOptions" :key="item.customer_code" :label="item.customer_name"
                :value="item.customer_code" />
            </el-select>
          </div>
          <div class="searchitem">
            <div class="lebel">订单状态</div>
            <el-select placeholder="请选择订单状态" v-model="orderWashStatus" clearable>
              <el-option label="全部" :value="null" />
              <el-option :value="7" label="成功完成" />
              <el-option :value="6" label="洗车完成未回调" />
              <el-option :value="5" label="启动洗车机成功" />
              <el-option :value="4" label="启动洗车机" />
              <el-option :value="3" label="启动道闸成功" />
              <el-option :value="2" label="启动道闸" />
              <el-option :value="1" label="进入洗车系统" />
              <el-option :value="0" label="订单初始化" />
              <el-option :value="-1" label="启动洗车机失败" />
              <el-option :value="-2" label="启动道闸失败" />
              <el-option :value="-3" label="完成回调异常" />
              <el-option :value="-4" label="完成回调失败" />
              <el-option :value="-4.1" label="回调订单不存在" />
            </el-select>
          </div>
          <div class="searchitem">
            <div class="lebel">洗车程序</div>
            <el-select placeholder="请选择洗车程序" v-model="carWashProgram" clearable>
              <el-option label="全部" :value="null" />
              <el-option :value="1" label="快洗" />
              <el-option :value="2" label="精洗" />
              <el-option :value="3" label="精洗+" />
            </el-select>
          </div>
          <div class="searchitem">
            <div class="lebel">订单标记</div>
            <el-select placeholder="请选择订单标记" v-model="mark" clearable>
              <el-option label="全部" :value="null" />
              <el-option value="vs" label="vs" />
              <el-option value="sto" label="sto" />
              <el-option value="cncl" label="cncl" />
              <el-option value="" label="空" />
            </el-select>
          </div>
        </div>
        <div class="searchbtns">
          <div class="search" @click="queryOrderList">搜索</div>
          <div class="export" @click="clear">清除</div>
          <div class="export" @click="exportData">导出</div>
        </div>
      </div>
      <!-- 订单总数显示 -->
      <div class="order-total">总计：<span>{{ total }}</span>条</div>
      <!-- 订单列表 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="order_no" label="订单号" width="200"> </el-table-column>
        <!--         <el-table-column prop="device_open_id" label="设备编号" align="center">
        </el-table-column> -->
        <el-table-column prop="car_wash_name" label="站点" align="center">
        </el-table-column>
        <el-table-column prop="customer_name" label="来源" align="center">
        </el-table-column>
        <el-table-column prop="start_time" label="创建时间" align="center">
          <template slot-scope="scope">
            {{ formatDisplayTime(scope.row.start_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="end_time" label="结束时间" align="center">
          <template slot-scope="scope">
            {{ formatDisplayTime(scope.row.end_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="car_wash_program" label="洗车程序" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.car_wash_program === '1'">快洗</div>
            <div v-if="scope.row.car_wash_program === '2'">精洗</div>
            <div v-if="scope.row.car_wash_program === '3'">精洗plus</div>
          </template>
        </el-table-column>
        <el-table-column prop="statusDesc" label="订单状态" align="center">
        </el-table-column>
        <el-table-column prop="mark" label="订单标记" align="center">
        </el-table-column>
        <!-- <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div class="orderdec" @click="toorderdec(scope.row.id)">查看</div>
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 分页 -->
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="pageNum" :page-sizes="[10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000]" :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { platformOrder, shopList } from "@/api/api";

export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      orderNo: "",
      //最近7天
      createTime: [new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000), new Date()],
      endTime: [],
      deviceId: null,
      qCustomerCode: null,
      orderWashStatus: null,
      mark: null,
      carWashProgram: null,
      shopOptions: [], // 站点选项
      customerOptions: [
        { customer_code: "YNKK01", customer_name: "咔咔" },
        { customer_code: "YCG01", customer_name: "悦车狗" },
        // 可以根据实际情况添加更多渠道
      ],
    };
  },
  async created() {
    await this.getShopList();
    await this.queryOrderList();
  },
  methods: {
    // 获取站点列表
    async getShopList() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await shopList({ admin_id });
        if (res && res.data) {
          this.shopOptions = res.data.list;
        }
        //console.log("站点列表", this.shopOptions);
      } catch (error) {
        console.log(error);
      }
    },
    // 获取平台订单列表
    async queryOrderList() {
      //console.log("查询订单列表");
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        let params = {
          admin_id,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        };

        // 只添加非null的查询条件
        if (this.orderNo) params.orderNo = this.orderNo;
        if (this.deviceId !== null) params.deviceId = this.deviceId;
        if (this.qCustomerCode !== null) params.qCustomerCode = this.qCustomerCode;
        if (this.orderWashStatus !== null) params.orderWashStatus = this.orderWashStatus;
        if (this.mark !== null) params.mark = this.mark;
        if (this.carWashProgram !== null) params.carWashProgram = this.carWashProgram;

        // 处理创建时间
        if (this.createTime && this.createTime.length === 2) {
          params.startCreateTime = this.formatDate(this.createTime[0]);
          params.endCreateTime = this.formatDate(this.createTime[1]);
        }

        // 处理结束时间
        if (this.endTime && this.endTime.length === 2) {
          params.startEndTime = this.formatDate(this.endTime[0]);
          params.endEndTime = this.formatDate(this.endTime[1]);
        }

        const res = await platformOrder(params);
        //console.log(res);
        this.tableData = res.data.records;
        this.total = Number(res.data.total);
      } catch (error) {
        console.log(error);
      }
    },
    /* // 订单详情
    toorderdec(id) {
      this.$router.push({ path: "/porder/porderdec", query: { id: id } });
    }, */
    clear() {
      this.orderNo = "";
      this.createTime = [new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000), new Date()];
      this.endTime = [];
      this.deviceId = null;
      this.qCustomerCode = null;
      this.orderWashStatus = null;
      this.mark = null;
      this.carWashProgram = null;
      this.pageNum = 1;
      this.pageSize = 10;
      this.queryOrderList();
    },

    formatDate(date) {
      // 如果date已经是时间戳格式（由于value-format="timestamp"设置）
      if (typeof date === 'number') {
        // 确保是秒级时间戳
        return Math.floor(date / 1000);
      }
      // 如果是Date对象
      if (date instanceof Date) {
        return Math.floor(date.getTime() / 1000);
      }
      // 如果是其他格式，尝试转换为Date对象
      return Math.floor(new Date(date).getTime() / 1000);
    },

    // 格式化显示时间为 yyyy-mm-dd hh:MM:ss
    formatDisplayTime(timestamp) {
      if (!timestamp) return '';

      // 处理ISO格式的字符串日期时间 "2025-03-12T03:54:15.000+00:00"
      if (typeof timestamp === 'string' && timestamp.includes('T')) {
        // 使用 Date 对象直接解析 ISO 格式字符串，这会正确处理时区
        const date = new Date(timestamp);
        // 转换为本地时区
        const localDate = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
        const year = localDate.getFullYear();
        const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
        const day = localDate.getDate().toString().padStart(2, '0');
        const hours = localDate.getHours().toString().padStart(2, '0');
        const minutes = localDate.getMinutes().toString().padStart(2, '0');
        const seconds = localDate.getSeconds().toString().padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }

      // 如果是数字字符串时间戳，转换为数字
      if (typeof timestamp === 'string') {
        timestamp = parseInt(timestamp);
      }

      // 如果是秒级时间戳，转换为毫秒级
      if (timestamp.toString().length === 10) {
        timestamp = timestamp * 1000;
      }

      const date = new Date(timestamp);
      // 转换为本地时区
      const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
      const year = localDate.getFullYear();
      const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
      const day = localDate.getDate().toString().padStart(2, '0');
      const hours = localDate.getHours().toString().padStart(2, '0');
      const minutes = localDate.getMinutes().toString().padStart(2, '0');
      const seconds = localDate.getSeconds().toString().padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    // 处理页码变化
    handleCurrentChange(val) {
      this.pageNum = val;
      this.queryOrderList();
    },

    // 处理每页显示条数变化
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1; // 重置为第一页
      this.queryOrderList();
    },

    // 导出数据为CSV
    async exportData() {
      if (this.tableData.length === 0) {
        this.$message.warning('没有数据可导出');
        return;
      }

      try {
        // 定义CSV表头
        const headers = [
          '编号',
          '订单号',
          '站点',
          '来源',
          '创建时间',
          '结束时间',
          '洗车程序',
          '订单状态',
          '订单标记'
        ];

        // 处理数据行
        const rows = this.tableData.map(item => {
          // 处理洗车程序显示
          let programText = '';
          if (item.car_wash_program === '1') programText = '快洗';
          else if (item.car_wash_program === '2') programText = '精洗';
          else if (item.car_wash_program === '3') programText = '精洗plus';

          return [
            item.id ? item.id.toString() : '',
            item.order_no || '',
            item.car_wash_name || '',
            item.customer_name || '',
            this.formatDisplayTime(item.start_time) || '',
            this.formatDisplayTime(item.end_time) || '',
            programText,
            item.statusDesc || '',
            item.mark || ''
          ];
        });

        // 组合CSV内容
        let csvContent = headers.join(',') + '\n';
        rows.forEach(row => {
          // 处理字段中可能包含逗号的情况，用双引号包裹
          const processedRow = row.map(field => {
            // 确保字段是字符串类型
            const fieldStr = String(field || '');

            // 如果字段包含逗号、双引号或换行符，则用双引号包裹
            if (fieldStr.includes(',') || fieldStr.includes('"') || fieldStr.includes('\n')) {
              // 将字段中的双引号替换为两个双引号
              return `"${fieldStr.replace(/"/g, '""')}"`;
            }
            return fieldStr;
          });
          csvContent += processedRow.join(',') + '\n';
        });

        // 添加UTF-8 BOM头，解决中文乱码问题
        const BOM = '\uFEFF';
        csvContent = BOM + csvContent;

        // 创建Blob对象，指定UTF-8编码
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // 创建下载链接
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        // 设置文件名（使用当前日期时间）
        const now = new Date();
        const fileName = `平台订单_${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}.csv`;

        // 设置下载属性
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';

        // 添加到DOM并触发点击
        document.body.appendChild(link);
        link.click();

        // 清理
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        this.$message.success('导出成功');
      } catch (error) {
        console.error('导出失败:', error);
        this.$message.error('导出失败');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;

  .searchbox {
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
  }

  .order-total {
    margin-bottom: 15px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;

    span {
      color: #0073ff;
      font-weight: bold;
    }
  }

  .ordertitle {
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }

  .searchitembox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .searchitem {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .lebel {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-right: 8px;
      }
    }
  }

  .searchbtns {
    display: flex;
    margin-top: 20px;
    //margin-left: 65px;

    // 共享样式的按钮基类
    %blue-button {
      width: 78px;
      height: 36px;
      background: #0073ff;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
    }

    .search {
      @extend %blue-button;
    }

    .export {
      @extend %blue-button;
      margin-left: 16px;
    }
  }

  .orderdec {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #2b6df9;
    cursor: pointer;
  }
}

::v-deep .el-input {
  width: 240px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}

::v-deep .el-table thead tr>th {
  background-color: #f5f5f5;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
}

::v-deep .el-table {
  margin-bottom: 50px;
}

::v-deep .el-pagination {
  position: absolute;
  bottom: 20px;
  right: 0;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
  background-color: #fff; // 进行修改未选中背景和字体// color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1890ff; // 进行修改选中项背景和字体color: #fff;
}
</style>
