<template>
  <div>
    <div class="shoptype">
      <div class="typetitle">门店类型</div>
      <div class="choosetype">
        <el-radio v-model="radioValue" label="1" @change="radioChange">直营门店</el-radio>
        <el-radio v-model="radioValue" label="2" @change="radioChange">加盟门店</el-radio>
      </div>
    </div>
    <div class="addtitle">基本信息</div>
    <div class="formbox">
      <el-form ref="form" :model="formData" :rules="rules">
        <el-form-item label="门店名称" prop="name">
          <el-input v-model="formData.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="机器编号" prop="equipment_id">
          <el-input v-model="formData.equipment_id" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="门店照片" prop="shopimg">
          <el-upload class="avatar-uploader" action="/api/6510eb6963781" :show-file-list="false"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img v-if="formData.shopimg" :src="$const.fileUrl + formData.shopimg" class="addimg" />
            <img src="../../assets/shopimg/add.png" alt="" class="addimg" v-else />
          </el-upload>
        </el-form-item>
        <el-form-item label="门店地址" prop="address">
          <el-col :span="3">
            <el-form-item prop="province">
              <el-select popper-class="eloption" :popper-append-to-body="true" @change="getShiList(formData.province)"
                v-model="formData.province" placeholder="省" clearable @clear="removeSheng()" v-loadmore="shengloadmore">
                <el-option v-for="item in shengList" :key="item.code" :value="item.id" :label="item.name" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item prop="city">
              <el-select popper-class="eloption" :popper-append-to-body="true" @change="getAreaList(formData.city)"
                v-model="formData.city" placeholder="市" v-loadmore="shiloadmore" clearable @clear="removeShi()">
                <el-option v-for="item in shiList" :key="item.code" :value="item.id" :label="item.name" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item prop="area">
              <el-select popper-class="eloption" :popper-append-to-body="true" @change="$forceUpdate()"
                v-model="formData.area" v-loadmore="arealoadmore" placeholder="区" clearable>
                <el-option v-for="item in areaList" :key="item.code" :value="item.id" :label="item.name" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item prop="shopaddress">
          <div class="address">
            <el-input placeholder="请输入" v-model="formData.shopaddress"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="门店经度" prop="shoplon">
          <div class="input-number">
            <el-input-number :max=180 :min=0 :precision=6 :step=0.0001 placeholder="请输入经度"
              v-model="formData.shoplon"></el-input-number>
          </div>
        </el-form-item>
        <el-form-item label="门店纬度" prop="shoplat">
          <div class="input-number">
            <el-input-number :max=90 :min=0 :precision=6 :step=0.0001 placeholder="请输入纬度"
              v-model="formData.shoplat"></el-input-number>
          </div>
          <div class="inputTip">请使用<a target="_blank" href="https://lbs.qq.com/getPoint/">选点工具</a>确定经纬度</div>
        </el-form-item>

        <!-- 门店控制用户 -->
        <div class="addtitle">门店控制用户</div>
        <div class="add-control-user">
          <el-button @click="handleAddControlUser" type="primary" size="small" icon="el-icon-plus">
            添加控制用户
          </el-button>
        </div>
        <el-table :data="formData.controlUsers" style="width: 780px">
          <el-table-column label="用户" width="280">
            <template slot-scope="scope">
              <el-select style="width: 260px" v-model="scope.row.user_id" filterable remote reserve-keyword
                placeholder="请输入用户昵称" :remote-method="remoteUserSearch" :loading="userLoading"
                @change="updateUserNickname(scope.row.user_id)">
                <el-option v-for="item in userList" :key="item.id" :label="item.nickname" :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="控制权限" width="380">
            <template slot-scope="scope">
              <el-checkbox-group v-model="scope.row.allowed_commands">
                <el-checkbox label="PAUSE">暂停</el-checkbox>
                <el-checkbox label="RESUME">恢复</el-checkbox>
                <el-checkbox label="CANCEL">取消</el-checkbox>
                <el-checkbox label="RESET">复位</el-checkbox>
              </el-checkbox-group>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button size="mini" type="danger"
                @click="handleDeleteControlUser(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 只在编辑模式显示的内容 -->
        <template v-if="isEdit">
          <el-form-item label="门店二维码">
            <img v-if="formData.codeimg" :src="$const.fileUrl + formData.codeimg" class="addimg" />
          </el-form-item>
          <el-form-item label="出库二维码">
            <img v-if="formData.chuimg" :src="$const.fileUrl + formData.chuimg" class="addimg" />
          </el-form-item>
          <!-- VS设置部分 -->
          <div class="addtitle">vs设置</div>
          <el-card style="max-width: 3.4rem">
            <el-form-item label="概率(%)" prop="rate">
              <div class="input-number">
                <el-input-number :max=100 :min=0 :step=1 :precision=0 placeholder="请输入概率" v-model="formData.vs.rate">
                </el-input-number>
              </div>
            </el-form-item>
            <el-form-item label="次数范围" prop="countRange">
              <div class="input-number">
                <el-slider v-model="formData.vs.countRange" range show-stops :max="10" />
              </div>
            </el-form-item>
            <el-form-item label="星期" prop="weekdays">
              <el-select size="large" v-model="formData.vs.weekdays" multiple placeholder="选择星期" style="width:2.4rem;">
                <el-option :key="1" label="星期一" :value="1" />
                <el-option :key="2" label="星期二" :value="2" />
                <el-option :key="3" label="星期三" :value="3" />
                <el-option :key="4" label="星期四" :value="4" />
                <el-option :key="5" label="星期五" :value="5" />
                <el-option :key="6" label="星期六" :value="6" />
                <el-option :key="7" label="星期日" :value="7" />
              </el-select>
            </el-form-item>
            <el-form-item label="时间范围" prop="timeRange">
              <div class="input-number">
                <el-time-picker v-model="formData.vs.timeRange" is-range range-separator="到" start-placeholder="开始时间"
                  end-placeholder="结束时间" format="HH:mm:ss" value-format="HH:mm:ss" />
              </div>
            </el-form-item>
            <el-form-item label="最大次数" prop="maxDayCount">
              <div class="input-number">
                <el-input-number :max=1000 :min=0 :step=1 :precision=0 placeholder="请输入最大次数"
                  v-model="formData.vs.maxDayCount">
                </el-input-number>
              </div>
            </el-form-item>
          </el-card>
        </template>
      </el-form>
    </div>
    <div class="affiliationbox">
      <div class="affiliationtitle">关联角色</div>
      <div class="affiliation" v-if="radioValue === '1'">
        <div class="affiliationlabel">门店承包商</div>
        <el-select v-model="formData.contractor" filterable placeholder="请选择">
          <el-option v-for="item in contractorList" :key="item.id" :label="item.nickname" :value="item.id">
            <template>
              <div class="selectbox">
                <img :src="$const.fileUrl + item.contractor_img" alt="" class="selectimg" />
                <div class="selectname">
                  {{ item.nickname }}
                </div>
              </div>
            </template>
          </el-option>
        </el-select>
      </div>
      <div class="affiliation" v-if="radioValue === '2'">
        <div class="affiliationlabel">门店加盟商</div>
        <el-select v-model="formData.allianceBusiness" filterable placeholder="请选择">
          <el-option v-for="item in allianceBusinessList" :label="item.nickname" :key="item.id" :value="item.id">
            <template>
              <div class="selectbox">
                <img :src="$const.fileUrl + item.franchisee_img" alt="" class="selectimg" />
                <div class="selectname">
                  {{ item.nickname }}
                </div>
              </div>
            </template>
          </el-option>
        </el-select>
      </div>
      <div class="affiliation" v-for="(item, index) in formData.firm" :key="index">
        <div class="affiliationlabel">关联厂商</div>
        <div class="choosefirmbox">
          <el-select v-model="item[isEdit ? 'manufacturer_id' : 'id']" filterable placeholder="请选择">
            <el-option v-for="firmItem in firmList" :key="firmItem.id" :label="firmItem.nickname" :value="firmItem.id">
              <template>
                <div class="selectbox">
                  <img :src="$const.fileUrl + firmItem.manufacturer_img" alt="" class="selectimg" />
                  <div class="selectname">
                    {{ firmItem.nickname }}
                  </div>
                </div>
              </template>
            </el-option>
          </el-select>
          <div class="upperLimit">
            返佣上限
            <el-input placeholder="请输入" v-model="item[isEdit ? 'commission_ceiling' : 'ratio']"></el-input>
            元
          </div>
        </div>
        <div class="delfirm" @click="deleteRow(item)" v-if="formData.firm.length > 1">
          删除
        </div>
      </div>
    </div>
    <div class="addfirm" @click="addfirm">新增厂商</div>
  </div>
</template>

<script>
import {
  cityList,
  contractorList,
  manufacturerList,
  allianceBusinessList,
  userList
} from "@/api/api";

export default {
  name: 'ShopForm',
  props: {
    // 是否为编辑模式
    isEdit: {
      type: Boolean,
      default: false
    },
    // 表单数据
    value: {
      type: Object,
      required: true
    },
    // 门店类型
    radio: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      radioValue: this.radio,
      formData: this.initFormData(),
      shengList: [],
      shengP: 1,
      shiList: [],
      shiP: 1,
      areaList: [],
      areaP: 1,
      // 承包商列表
      contractorList: [],
      contractorP: 1,
      // 厂商列表
      firmList: [],
      firmP: 1,
      // 加盟商列表
      allianceBusinessList: [],
      allianceBusinessP: 1,
      // 用户列表
      userList: [],
      userLoading: false,
      isInitialized: false,
      rules: {
        name: [{ required: true, message: "请输入门店名称", trigger: "blur" }],
        equipment_id: [{ required: true, message: "请输入机器编号", trigger: "blur" }],
        shopimg: [
          { required: true, message: "请上传门店照片", trigger: "change" },
        ],
        province: [{ required: true, message: "请选择省", trigger: "change" }],
        city: [{ required: true, message: "请选择市", trigger: "change" }],
        area: [{ required: true, message: "请选择区", trigger: "change" }],
      }
    };
  },
  directives: {
    loadmore: {
      inserted(el, binding) {
        const dom = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        dom.addEventListener("scroll", function (e) {
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
  watch: {
    value: {
      handler(val) {
        this.formData = val;
      },
      deep: true
    },
    formData: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true
    },
    radio(val) {
      this.radioValue = val;
    },
    radioValue(val) {
      this.$emit('update:radio', val);
    },
    "formData.province"(newValue) {
      if (this.isInitialized) {
        this.shiP = 1;
        this.areaP = 1;
        this.formData.city = "";
        this.formData.area = "";
        this.shiList = [];
        this.areaList = [];
      } else {
        this.isInitialized = true;
      }
    },
    "formData.controlUsers": {
      handler(newUsers) {
        // 将已有用户信息加入userList以便正确显示昵称
        if (this.isEdit && newUsers && newUsers.length > 0) {
          const existingUsers = newUsers
            .filter(user => user.user_id && user.nickname)
            .map(user => ({
              id: user.user_id,
              nickname: user.nickname
            }));

          if (existingUsers.length > 0) {
            // 合并现有用户列表和新用户，确保不重复
            this.userList = [...this.userList];
            existingUsers.forEach(user => {
              if (!this.userList.some(u => u.id === user.id)) {
                this.userList.push(user);
              }
            });
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.getcityList();
    this.getcontractorList();
    this.getfirmList();
    this.getallianceBusinessList();
    this.loadInitialUserList();
  },
  methods: {
    // 初始化表单数据
    initFormData() {
      const formData = JSON.parse(JSON.stringify(this.value));
      // 如果没有控制用户，初始化为空数组
      if (!formData.controlUsers) {
        formData.controlUsers = [];
      }
      return formData;
    },

    // 加载已有控制用户信息到用户列表
    loadInitialUserList() {
      if (this.isEdit && this.formData.controlUsers && this.formData.controlUsers.length > 0) {
        // 将已有的用户信息添加到用户列表中，避免只显示ID
        const userIds = this.formData.controlUsers
          .filter(user => user.user_id && user.nickname)
          .map(user => ({
            id: user.user_id,
            nickname: user.nickname
          }));

        if (userIds.length > 0) {
          this.userList = userIds;
        }
      }
    },

    // 添加控制用户
    handleAddControlUser() {
      this.formData.controlUsers.push({
        user_id: '',
        nickname: '',
        allowed_commands: []
      });
    },

    // 删除控制用户
    handleDeleteControlUser(index, row) {
      this.$confirm('确认删除该控制用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.formData.controlUsers.splice(index, 1);
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    // 远程搜索用户
    async remoteUserSearch(query) {
      if (query !== '') {
        this.userLoading = true;
        try {
          const admin_id = sessionStorage.getItem("admin_id");
          const res = await userList({
            admin_id,
            nickname: query,
            p: 1
          });
          this.userList = res.data.list || [];
        } catch (error) {
          console.log(error);
        } finally {
          this.userLoading = false;
        }
      } else {
        this.userList = [];
      }
    },

    // 用户选择变更时更新nickname
    updateUserNickname(userId) {
      if (!userId) return;

      // 查找当前用户列表中的用户
      const selectedUser = this.userList.find(user => user.id === userId);
      if (selectedUser) {
        // 查找对应的控制用户并更新nickname
        const controlUser = this.formData.controlUsers.find(user => user.user_id === userId);
        if (controlUser) {
          controlUser.nickname = selectedUser.nickname;
        }
      }
    },

    // 表单验证方法
    validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate((valid) => {
          // 验证控制用户
          const controlUsersValid = this.validateControlUsers();
          resolve(valid && controlUsersValid);
        });
      });
    },

    // 验证控制用户
    validateControlUsers() {
      // 如果没有控制用户，直接返回有效
      if (this.formData.controlUsers.length === 0) {
        return true;
      }

      // 用于检查用户ID是否重复
      const userIds = new Set();

      // 验证每个控制用户
      for (let i = 0; i < this.formData.controlUsers.length; i++) {
        const user = this.formData.controlUsers[i];

        // 检查用户ID是否为空
        if (!user.user_id) {
          this.$message.error(`第 ${i + 1} 个控制用户未选择`);
          return false;
        }

        // 检查用户ID是否重复
        if (userIds.has(user.user_id)) {
          this.$message.error(`控制用户重复`);
          return false;
        }
        userIds.add(user.user_id);

        // 检查是否选择了权限
        if (!user.allowed_commands || user.allowed_commands.length === 0) {
          this.$message.error(`第 ${i + 1} 个控制用户至少需要选择一个权限`);
          return false;
        }
      }

      return true;
    },

    // 获取城市列表
    async getcityList() {
      try {
        const res = await cityList({ pid: 0, p: this.shengP });
        this.shengList = this.shengList.concat(res.data.list);
      } catch (error) {
        console.log(error);
      }
    },
    // 获得市
    async getShiList() {
      this.$forceUpdate();
      try {
        const res = await cityList({ pid: this.formData.province, p: this.shiP, z: 30 });
        this.shiList = this.shiList.concat(res.data.list);
      } catch (error) {
        console.log(error);
      }
    },
    // 获得区
    async getAreaList() {
      this.$forceUpdate();
      try {
        const res = await cityList({ pid: this.formData.city, p: this.areaP, z: 30 });
        this.areaList = this.areaList.concat(res.data.list);
      } catch (error) {
        console.log(error);
      }
    },
    removeSheng() {
      this.$forceUpdate();
      this.shiP = 1;
      this.areaP = 1;
      this.formData.province = "";
      this.formData.city = "";
      this.formData.area = "";
      this.shiList = [];
      this.areaList = [];
    },
    removeShi() {
      this.shiP = 1;
      this.areaP = 1;
      this.$forceUpdate();
      this.formData.city = "";
      this.formData.area = "";
      this.areaList = [];
    },
    // 图片上传成功回调
    handleAvatarSuccess(res, file) {
      this.formData.shopimg = file.response.data;
    },
    // 图片上传前验证
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 触底加载更多
    shengloadmore() {
      this.shengP++;
      this.getcityList();
    },
    shiloadmore() {
      this.shiP++;
      this.getShiList();
    },
    arealoadmore() {
      this.areaP++;
      this.getAreaList();
    },
    // 新增厂商
    addfirm() {
      if (this.isEdit) {
        this.formData.firm.push({
          manufacturer_id: "",
          commission_ceiling: "",
        });
      } else {
        this.formData.firm.push({
          id: "",
          ratio: "",
        });
      }
    },
    // 删除厂商
    deleteRow(item) {
      const index = this.formData.firm.indexOf(item);
      if (index !== -1 && this.formData.firm.length > 1) {
        this.formData.firm.splice(index, 1);
      }
    },
    // 获取承包商列表
    async getcontractorList() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await contractorList({ admin_id, p: this.contractorP });
        this.contractorList = this.contractorList.concat(res.data.list);
      } catch (error) {
        console.log(error);
      }
    },
    // 获取厂商列表
    async getfirmList() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await manufacturerList({ admin_id, p: this.firmP });
        this.firmList = this.firmList.concat(res.data.list);
      } catch (error) {
        console.log(error);
      }
    },
    // 获取加盟商列表
    async getallianceBusinessList() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await allianceBusinessList({
          admin_id,
          p: this.allianceBusinessP,
        });
        this.allianceBusinessList = this.allianceBusinessList.concat(
          res.data.list
        );
      } catch (error) {
        console.log(error);
      }
    },
    // 门店类型切换
    radioChange() {
      this.$emit('radio-change', this.radioValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.shoptype {
  .typetitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }

  .choosetype {
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
  }
}

.addtitle {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 22px;
  margin-bottom: 20px;
}

.formbox {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 20px;

  .inputTip {
    width: 200px;
    font-size: 14px;
    font-weight: 400;
    color: #e92b2b;
    line-height: 36px;
    margin-right: 20px;
    margin-left: 100px;
  }
}

.addimg {
  width: 120px;
  height: 120px;
  cursor: pointer;
}

.address {
  margin-left: 100px;
}

.input-number {
  width: 2.40625rem;
  margin-left: 100px;

  .el-input-number {
    width: 100%;
  }
}

.affiliationbox {
  .affiliationtitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 10px;
  }

  .affiliation {
    display: flex;
    margin-left: 20px;
    align-items: center;
    margin-bottom: 20px;

    .affiliationlabel {
      width: 80px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #606266;
      line-height: 20px;
      margin-right: 20px;
    }

    .choosefirmbox {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #606266;
      line-height: 20px;

      .el-input {
        width: 146px;
        height: 36px;
        margin-left: 8px;
        margin-right: 8px;
      }
    }

    .delfirm {
      width: 80px;
      height: 36px;
      font-size: 14px;
      margin-left: 20px;
      background-color: red;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}

.addfirm {
  width: 96px;
  height: 36px;
  background: #0073ff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
  margin-left: 120px;
  cursor: pointer;
}

.selectbox {
  display: flex;
  align-items: center;

  .selectimg {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 20px;
  }
}

::v-deep .el-input {
  width: 462px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}

::v-deep .el-form-item__label {
  min-width: 100px;
  padding-right: 20px;
}

::v-deep .el-select {
  width: 146px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  margin-right: 12px;

  .el-input {
    width: 100%;
    height: 100%;
  }
}

.add-control-user {
  width: 865px;
  margin: 2px 0 10px 0;
  text-align: right;
}
</style>
