<template>
  <!-- 首页 -->
  <div class="container">
    <div class="headerbox">
      <!-- 数据概览 -->
      <div class="dataOverview">
        <div class="topbox">
          <div class="datatit">数据概览</div>
          <!-- <div class="datadate">更新时间：2023-07-10 00:00</div> -->
        </div>
        <div class="mainbox">
          <div class="itemmainbox">
            <div class="itemmaintitle">今日收入(元)</div>
            <div class="itemmainnum">{{ jinrishouru }}</div>
            <div class="itemmaintrend">
              较昨日<i class="up" v-if="jinrishourutendencyicon == 1"></i>
              <i class="down" v-if="jinrishourutendencyicon == 2"></i>
              <span v-if="jinrishourutendency == 'NaN%'">0%</span>
              <span v-else>{{ jinrishourutendency }}</span>
            </div>
          </div>
          <div class="itemmainbox">
            <div class="itemmaintitle">今日订单数量</div>
            <div class="itemmainnum">{{ jinridingdan }}</div>
            <div class="itemmaintrend">
              较昨日<i class="up" v-if="jinridingdantendencyicon == 1"></i>
              <i class="down" v-if="jinridingdantendencyicon == 2"></i><span
                v-if="jinridingdantendency == 'NaN%'">0%</span>
              <span v-else>{{ jinridingdantendency }}</span>
            </div>
          </div>
          <div class="itemmainbox">
            <div class="itemmaintitle">累计收益(元)</div>
            <div class="itemmainnum">{{ leijishouyi }}</div>
            <div class="itemmaintrend">
              较昨日
              <i class="up" v-if="leijishouyitendencyicon == 1"></i>
              <i class="down" v-if="leijishouyitendencyicon == 2"></i>
              <span v-if="leijishouyitendency == 'NaN%'">0%</span>
              <span v-else>{{ leijishouyitendency }}</span>
            </div>
          </div>
          <div class="itemmainbox">
            <div class="itemmaintitle">开通福利官数量</div>
            <div class="itemmainnum">{{ fuliguan }}</div>
            <div class="itemmaintrend">
              较昨日<i class="up" v-if="fuliguantendencyicon == 1"></i>
              <i class="down" v-if="fuliguantendencyicon == 2"></i>
              <span v-if="fuliguantendency == 'NaN%'">0%</span>
              <span v-else>{{ fuliguantendency }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 常用工具 -->
      <div class="commonTools">
        <div class="tooltitle">常用工具</div>
        <div class="tools">
          <div class="toolbox" @click="toshop">
            <img src="../../assets/homeimg/store.png" alt="" />
            <div class="toolname">门店管理</div>
          </div>
          <div class="toolbox" @click="toorder">
            <img src="../../assets/homeimg/order.png" alt="" />
            <div class="toolname">订单管理</div>
          </div>
          <div class="toolbox" @click="toservice">
            <img src="../../assets/homeimg/service.png" alt="" />
            <div class="toolname">服务管理</div>
          </div>
          <div class="toolbox" @click="todiscount">
            <img src="../../assets/homeimg/discount.png" alt="" />
            <div class="toolname">邀请管理</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 营收统计 -->
    <div class="revenueStatistics">
      <div class="staticContent">
        <div class="revenueStatisticstitle">
          洗车量(按天)
        </div>
        <div class="staticSearch">
          时间段
          <el-date-picker v-model="dateRange" type="daterange" range-separator="-" start-placeholder="开始时间"
            end-placeholder="结束时间" :default-time="['00:00:00', '23:59:59']" @change="onPickDate"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
        <div class="staticSearch">
          <el-radio-group v-model="channel" @change="doChart">
            <el-radio-button :label="1" size="large">全渠道</el-radio-button>
            <el-radio-button :label="2" size="large">悦车狗</el-radio-button>
          </el-radio-group>
        </div>
        <div class="staticSearch">
          <el-radio-group v-model="dataType" @change="doDataAndChart">
            <el-radio-button :label="1" size="large">完成数据</el-radio-button>
            <el-radio-button :label="2" size="large">未完成数据</el-radio-button>
            <el-radio-button :label="3" size="large">标记完成数据</el-radio-button>
            <el-radio-button :label="0" size="large">所有数据</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div id="echarts"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { homeData, orderStatic } from "@/api/api";
export default {
  data() {
    return {
      jinrishouru: 0,
      jinridingdan: 0,
      leijishouyi: 0,
      fuliguan: 0,
      jinrishourutendency: "",
      jinrishourutendencyicon: 0,
      jinridingdantendency: "",
      jinridingdantendencyicon: 0,
      leijishouyitendency: "",
      leijishouyitendencyicon: 0,
      fuliguantendency: "",
      fuliguantendencyicon: 0,
      dateRange: [],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().setHours(0, 0, 0, 0));
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近2周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 13);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近1个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近2个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      channel: 1,
      dataType: 1,
      date: [],
      mdate: [],
      series: [],
      mseries: [],
      orderStatic: [], //接口端统计数据
      morderStatic: [], //悦车狗端统计数据
      option: {},
      mOption: {},
      myChart: null,
    };
  },
  async mounted() {
    try {
      //let admin_id = sessionStorage.getItem("admin_id");
      //const res = await homeData({ admin_id });
      //console.log(res);
      //let time = res.data.zhexian.reverse().slice(-7).map((item) => item.add_time.slice(5, 10));
      //this.date = time.join(",");
      // let money = res.data.zhexian.slice(-7).map((item) => item.money);
      // this.earn = money.join(",");
      this.myChart = echarts.init(document.getElementById("echarts"));
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    } catch (error) {
      console.log(error);
    }
  },
  async created(e) {
    const now = new Date();
    const start = new Date();
    this.dateRange = [start.setDate(now.getDate() - 6), now.getTime()];//默认14天
    this.gethomeData();
    this.doDataAndChart();
  },
  methods: {
    // 获取首页数据
    async gethomeData() {
      try {
        let admin_id = sessionStorage.getItem("admin_id");
        const res = await homeData({ admin_id });
        console.log("首页上半部分数据", res);
        this.jinridingdan = Number(res.data.jintian.dingdanshuliang);
        this.jinrishouru = Number(res.data.jintian.jinrishouru);
        this.leijishouyi = Number(res.data.jintian.leijishouyi);
        this.fuliguan = Number(res.data.jintian.fuliguanshuliang);
        // let time = res.data.zhexian.map((item) => item.add_time);
        // this.date = time.join(",");
        //let money = res.data.zhexian.map((item) => item.money);
        //this.earn = money.join(",");
        if (this.jinrishouru - Number(res.data.zuotian.jinrishouru) > 0) {
          this.jinrishourutendencyicon = 1;
        } else {
          this.jinrishourutendencyicon = 2;
        }
        this.jinrishourutendency =
          (Math.abs(this.jinrishouru - Number(res.data.zuotian.jinrishouru)) /
            Number(res.data.zuotian.jinrishouru)).toFixed(2) *
          100 +
          "%";
        if (this.jinrishourutendency === "Infinity%") {
          this.jinrishourutendency = Number(this.jinrishouru).toFixed(2) * 100 + "%";
        }
        if (this.jinridingdan - Number(res.data.zuotian.dingdanshuliang) > 0) {
          this.jinridingdantendencyicon = 1;
        } else {
          this.jinridingdantendencyicon = 2;
        }
        this.jinridingdantendency =
          (Math.abs(
            this.jinridingdan - Number(res.data.zuotian.dingdanshuliang)
          ) /
            Number(res.data.zuotian.dingdanshuliang)).toFixed(2) *
          100 +
          "%";
        if (this.jinridingdantendency === "Infinity%") {
          this.jinridingdantendency = Number(this.jinridingdan).toFixed(2) * 100 + "%";
        }
        if (this.leijishouyi - Number(res.data.zuotian.leijishouyi) > 0) {
          this.leijishouyitendencyicon = 1;
        } else {
          this.leijishouyitendencyicon = 2;
        }
        this.leijishouyitendency =
          (Math.abs(this.leijishouyi - Number(res.data.zuotian.leijishouyi)) /
            Number(res.data.zuotian.leijishouyi)).toFixed(2) *
          100 +
          "%";
        if (this.leijishouyitendency === "Infinity%") {
          this.leijishouyitendency = Number(this.leijishouyi).toFixed(2) * 100 + "%";
        }
        if (this.fuliguan - Number(res.data.zuotian.fuliguanshuliang) > 0) {
          this.fuliguantendencyicon = 1;
        } else {
          this.fuliguantendencyicon = 2;
        }
        this.fuliguantendency =
          (Math.abs(this.fuliguan - Number(res.data.zuotian.fuliguanshuliang)) /
            Number(res.data.zuotian.fuliguanshuliang)).toFixed(2) *
          100 +
          "%";
        if (this.fuliguantendency === "Infinity%") {
          this.fuliguantendency = Number(this.fuliguan).toFixed(2) * 100 + "%";
        }
      } catch (error) {
        console.log(error);
      }
    },
    async genOrderStatic() {
      //console.log("daterange", this.dateRange);
      try {
        const res = await orderStatic({ start: 0 | (this.dateRange[0] / 1000), end: 0 | (this.dateRange[1] / 1000), type: this.dataType });
        console.log("洗车量统计", res);
        if (res && res.code === 1) {
          this.orderStatic = res.data.adata;
          this.morderStatic = res.data.mdata;
          this.initOption(); //初始化接口端图标配置
          this.initMoption(); //初始化悦车狗端图标配置
          return true;
        } else {
          return false;
        };
      } catch (e) {
        console.log('获取统计数据异常', e);
        return false;
      }
    },
    initOption() {
      this.series = [];
      this.date = [];
      for (let index = 0; index < this.orderStatic.length; index++) {
        const item = this.orderStatic[index];
        let mdateStr = item.days.substr(4);
        mdateStr = mdateStr.slice(0, 2) + "/" + mdateStr.slice(2);
        if (this.date && this.date.indexOf(mdateStr) < 0) {
          this.date.push(mdateStr);
        }
        if (this.dataType === 3 && item.stype === undefined) {
          item.stype = "未标记"
        }
        let serieName = item.car_wash_name + "[" + item.stype + "]";
        let findSerie = false;
        this.series.forEach(serie => {
          if (serie.name === serieName) {
            findSerie = true;
            serie.data.push([mdateStr, item.count]);
          }
        });
        if (!findSerie) {
          this.series.push({
            name: serieName,
            stack: item.car_wash_name,
            type: "bar",
            label: {
              //rotate: 90,
              show: true,
            },
            universalTransition: {
              enabled: true,
              divideShape: 'clone'
            },
            emphasis: {
              focus: 'series',
              blurScope: 'coordinateSystem',
              itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0,0,0,0.3)'
              },
              label: {
                show: true,
                //rotate: 0,
                //formatter: '{c}',
              }
            },
            data: [[mdateStr, item.count]],
            symbol: "circle", //拐点的形状
            symbolSize: 14, //拐点大小
            //   symbol: "none",//没有小球和hover效果
            // type: "line",
            // smooth: true, //是否平滑
            showSymbol: false,
            tooltip: {
              show: true,
            },
          });
        };
      }
      // this.myChart.off('legendselectchanged') //解决重复触发
      // this.myChart.on('legendselectchanged', (e) => {
      //   console.log("legendselectchanged", e)
      // })
      // 使用刚指定的配置项和数据显示图表
      this.option = this.getChartOption(this.date, this.series.sort((a, b) => {
        let res = a.stack.localeCompare(b.stack);
        if (res === 0) {
          res = a.name.replace(a.stack, "").localeCompare(b.name.replace(a.stack, ""));
        }
        return res;
      }));
    },
    initMoption() {
      this.mseries = [];
      this.mdate = [];
      for (let index = 0; index < this.morderStatic.length; index++) {
        const item = this.morderStatic[index];
        let mdateStr = item.days.substr(4);
        mdateStr = mdateStr.slice(0, 2) + "/" + mdateStr.slice(2);
        if (this.mdate && this.mdate.indexOf(mdateStr) < 0) {
          this.mdate.push(mdateStr);
        }
        if (this.dataType === 3 && item.type === undefined) {
          item.type = "未标记"
        }
        let serieName = item.short_name + '[' + item.type + ']';
        let findSerie = false;
        this.mseries.forEach(serie => {
          if (serie.name === serieName && serie.stack === item.short_name) {
            findSerie = true;
            serie.data.push([mdateStr, item.count]);
          }
        });
        if (!findSerie) {
          this.mseries.push({
            name: serieName,
            stack: item.short_name,
            type: "bar",
            label: {
              //rotate: 90,
              show: true,
            },
            universalTransition: {
              enabled: true,
              divideShape: 'clone'
            },
            emphasis: {
              focus: 'series',
              blurScope: 'coordinateSystem',
              itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0,0,0,0.3)'
              },
              label: {
                show: true,
                //rotate: 0,
                //formatter: '{c}',
              }
            },
            data: [[mdateStr, item.count]],
            symbol: "circle", //拐点的形状
            symbolSize: 14, //拐点大小
            //   symbol: "none",//没有小球和hover效果
            // type: "line",
            // smooth: true, //是否平滑
            showSymbol: false,
            tooltip: {
              show: true,
            },
          });
        };
      };
      this.mOption = this.getChartOption(this.mdate, this.mseries.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }));
    },
    doChart() {
      this.myChart.clear();
      if (this.channel === 1) {
        this.myChart.setOption(this.option);
      } else if (this.channel === 2) {
        /*  const that = this;
         this.mOption.graphic = [
           {
             type: 'text',
             left: 10,
             top: 20,
             style: {
               text: '返回',
               fill: '#05ce74',
               fontSize: 18
             },
             onclick: function () {
               that.myChart.clear();
               that.myChart.setOption(that.option);
             }
           }
         ]; */
        this.myChart.setOption(this.mOption);
      }
    },
    async doDataAndChart() {
      const rt = await this.genOrderStatic();
      if (rt) {
        this.doChart();
      }
    },
    getChartOption(xdata, ydata, tipType = 1) {
      // 指定图表的配置项和数据
      const option = {
        tooltip: {
          show: true,
          trigger: tipType === 1 ? "axis" : "item",
          axisPointer: {
            //坐标轴指示器，坐标轴触发有效，
            type: "cross", //默认为line，line直线，cross十字准星，shadow阴影
            crossStyle: {
              color: "#fff",
            },
          },
        },
        /* color: ['#012030', '#DAFDBA', '#F2C12E', '#FF5A33', '#45214A', '#9FC131', '#DBF227', '#D6D58E', '#005C53', '#267365', '#F23030', '#F29F05', '#F28705', '#CCDDCC', '#CCCC33', '#FF4858', '#CC9933', '#00CCC0', '#FFFF00', '#669999', '#FF9966', '#996600', '#CCCC00', '#662400', '#CC6600', '#009999', '#72F2EB', '#747F7F', '#336633'], */
        color: ['#2B88D9', '#010A26', '#0073ff', '#034C8C', '#D9D9D9', '#6393A6', '#41BFBF', '#59573B', '#79C4F2', '#1A5AD9', '#735B30', '#DFF2F2', '#154EBF', '#419FD9', '#010A26', '#79A2F2', '#D9B779', '#F2F2F2', '#034C8C', '#D9C6B0', '#384001', '#005C53', '#BFA473', '#73A9D9', '#59452C', '#3E5902', '#BF5B04', '#6E8C03'],
        legend: {
          //data: this.legend,
        },
        //selectedMode: 'series',
        barMaxWidth: 50,
        xAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          boundaryGap: false,
          data: xdata,
          axisLine: {
            show: true, // 是否显示坐标轴轴线
            symbol: ["none", "none"], // 轴线两端箭头，两个值，none表示没有箭头，arrow表示有箭头
            lineStyle: {
              color: "#EAEAEA", // 坐标轴线线的颜色
              width: "1", // 坐标轴线线宽
              type: "solid", // 坐标轴线线的类型（'solid'，实线类型；'dashed'，虚线类型；'dotted',点状类型）
            },
          },
          axisTick: {
            show: false, // 是否显示坐标轴刻度
            alignWithLabel: true
          },
          axisLabel: {
            show: true, // 是否显示刻度标签
            //interval: "0", // 坐标轴刻度标签的显示间隔，在类目轴中有效.0显示所有
            inside: false, // 刻度标签是否朝内，默认朝外
            // rotate: 90, // 刻度标签旋转的角度，在类目轴的类目标签显示不下的时候可以通过旋转防止标签之间重叠；旋转的角度从 -90 度到 90 度
            //margin: 16, // 刻度标签与轴线之间的距离
            // formatter 刻度标签的内容格式器，支持字符串模板和回调函数两种形式
            color: "#999999", // 刻度标签文字的颜色
            fontStyle: "normal", // 文字字体的风格（'normal'，无样式；'italic'，斜体；'oblique'，倾斜字体）
            fontWeight: "normal", // 文字字体的粗细（'normal'，无样式；'bold'，加粗；'bolder'，加粗的基础上再加粗；'lighter'，变细；数字定义粗细也可以，取值范围100至700）
            //fontSize: "14", // 文字字体大小
            //align: "left", // 文字水平对齐方式，默认自动（'left'，'center'，'right'）
            //verticalAlign: "left", // 文字垂直对齐方式，默认自动（'top'，'middle'，'bottom'
            // lineHeight: "50", // 行高 ）
            // backgroundColor: "red", // 文字块背景色，例：'#123234', 'red', 'rgba(0,23,11,0.3)'
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false //y轴不显示刻度线
          },
          splitLine: {
            show: true
          },
          axisLabel: {
            margin: 100,
            formatter: "{value}辆" //字符串模板
          },
        },
        series: ydata,
      };
      return option;
    },
    onPickDate() {
      //console.log(this.dateRange[1].getTime(), this.dateRange[0].getTime());
      let maxEndTime = new Date();
      maxEndTime.setDate(maxEndTime.getDate() + 1);
      if (this.dateRange[1].getTime() - this.dateRange[0].getTime() > 1000 * 60 * 60 * 24 * this.$const.maxDateRange) {
        alert("范围不能超过" + this.$const.maxDateRange + "天");
        //this.dateRange[1].setDate(this.dateRange[0].getDate() + maxDays);
      } else if (this.dateRange[1].getTime() > maxEndTime) {
        alert("结束时间不能超过今天！");
      } else {
        this.doDataAndChart();
      }
    },
    // 门店管理
    toshop() {
      this.$router.push("/shop/shopindex");
    },
    // 小程序订单管理
    toorder() {
      this.$router.push("/order/orderindex");
    },
    // 平台订单管理
    toporder() {
      this.$router.push("/porder/porderindex");
    },
    // 服务管理
    toservice() {
      this.$router.push("/service/serviceindex");
    },
    // 邀请管理
    todiscount() {
      this.$router.push("/promotion/invite");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .headerbox {
    display: flex;
  }

  .dataOverview {
    width: 80%;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    padding: 24px;

    .topbox {
      display: flex;
      justify-content: space-between;
      align-content: center;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 24px;
    }

    .datatit {
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 25px;
    }

    .datadate {
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 25px;
    }
  }

  .mainbox {
    display: flex;
    justify-content: space-around;

    .itemmainbox {
      text-align: left;
      margin-top: 48px;

      .itemmaintitle {
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
      }

      .itemmainnum {
        font-size: 30px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 42px;
        margin-top: 8px;
        margin-bottom: 4px;
      }

      .itemmaintrend {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        display: flex;
        align-items: center;

        .up {
          width: 0;
          height: 0;
          border-right: 8px solid transparent;
          border-left: 8px solid transparent;
          border-bottom: 8px solid #05ce74;
          margin-left: 7px;
          margin-right: 2px;
          line-height: 20px;
        }

        .down {
          width: 0;
          height: 0;
          border-right: 8px solid transparent;
          border-left: 8px solid transparent;
          border-top: 8px solid #ff5733;
          line-height: 20px;
          margin-left: 7px;
          margin-right: 2px;
        }
      }
    }
  }

  .commonTools {
    width: 20%;
    height: 240px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    margin-left: 16px;
    padding: 12px 0 16px 12px;

    .tooltitle {
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 25px;
      text-align: left;
      margin-bottom: 16px;
    }

    .tools {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .toolbox {
        width: 35%;
        margin-bottom: 24px;
        cursor: pointer;

        img {
          width: 42px;
          height: 42px;
        }

        .toolname {
          margin-top: 12px;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
        }
      }
    }
  }

  .revenueStatistics {
    width: 100%;
    background-color: #fff;
    height: 50vh;
    margin-top: 16px;
    padding: 20px;
    box-sizing: border-box;

    .staticContent {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      //justify-content: normal;
      padding: 10px;

      .revenueStatisticstitle {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 25px;
        text-align: left;
        padding-bottom: 20px;
        border-bottom: 1px solid #eeeeee;
      }

      .staticSearch {
        font-size: 14px;
        padding-left: 50px;
      }
    }

    #echarts {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
