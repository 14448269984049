import Vue from 'vue'

const bindToGlobal = (obj, key = 'var') => {
  if (typeof Vue.prototype[key] === 'undefined') {
    Vue.prototype[key] = {};
  };
  for (let i in obj) {
    Vue.prototype[key][i] = obj[i];
  };
}

export default {
  install() {
    const fileUrl = "https://yapi.ycargo.cn/" //正式
    //const fileUrl = "https://test.yapi.ycargo.cn/" //测试
    //const fileUrl = "https://dyapi.ycargo.cn/" //开发
    const maxDateRange = 61;//按天统计最大范围
    //console.log("fileUrl in global", fileUrl);
    bindToGlobal({ fileUrl, maxDateRange }, '$const');
    //console.log("vue prototype", Vue.prototype);
  }
}
