import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
import Home from "@/views/home";
import Login from "@/views/login";
import Shop from "@/views/shop";
import Service from "@/views/service";
import TopUpBill from '@/views/bill/TopUpBill'
import welfareBill from '@/views/bill/welfareBill'
import blanceChange from '@/views/bill/blanceChange'
import Order from "@/views/order";
import POrder from "@/views/porder";
import PeopleList from "@/views/people/peopleList.vue";
import WelfareList from "@/views/people/welfareList";
import ContractorList from "@/views/people/contractorList";
import AllianceBusinessList from "@/views/people/allianceBusinessList";
import ManufacturerList from "@/views/people/manufacturerList";
import CityAgency from "@/views/people/cityAgency";
import Timecard from "@/views/promotion/timecard";
import TikTok from "@/views/promotion/TikTok";
import Invite from "@/views/promotion/invite";
import Welfaremanage from "@/views/role/welfaremanage";
import Contractormanage from "@/views/role/contractormanage";
import AllianceBusinessmanege from "@/views/role/allianceBusinessmanege";
import Manufacturermanage from "@/views/role/manufacturermanage";
import CityAgencymanage from "@/views/role/cityAgencymanage";
// import Withdraw from "@/views/widthdraw";
import Carousel from "@/views/other/carousel";
import Applyforcontractor from "@/views/other/applyforcontractor";
import Applyforalliancebusiness from "@/views/other/applyforalliancebusiness";
import Rechargebalance from "@/views/other/Rechargebalance";
import Balanceagreement from "@/views/other/balanceagreement";
import Privacyagreement from "@/views/other/privacyagreement";
import Carwashnotice from "@/views/other/carwashnotice";
import Phone from "@/views/other/phone";
import Agreement from "@/views/other/agreement";
import Addshop from "@/views/shop/addshop";
import Editshop from "@/views/shop/editshop";
import Orderdec from "@/views/order/orderdec";
import Peopledec from "@/views/people/peopledec";
import Welfaredec from "@/views/people/welfaredec";
import Contractordec from "@/views/people/contractordec";
import Addcontractor from "@/views/people/addcontractor";
import AllianceBusinessdec from "@/views/people/allianceBusinessdec";
import AddallianceBusiness from "@/views/people/addallianceBusiness";
import Addmanufacturer from "@/views/people/addmanufacturer";
import Manufacturerdec from "@/views/people/manufacturerdec";
import CityAgencydec from "@/views/people/cityAgencydec";
import AddcityAgency from "@/views/people/addcityAgency";
import Newcard from "@/views/promotion/newcard";
import Yearcardadd from "@/views/promotion/yearcardadd";
import Carddec from "@/views/promotion/carddec";
import Withdrawdec from "@/views/widthdraw/withdrawdec";
import Addcarousel from "@/views/other/addcarousel";
import Carouseldec from "@/views/other/carouseldec";
import Rechargebalancedec from "@/views/other/Rechargebalancedec";
import addRechargebalance from "@/views/other/addRechargebalance";
import Overtimepay from "@/views/other/overtimepay";
import MeituanSessioin from "@/views/other/meituan";
import Userinfo from "@/views/home/userinfo";
import userApply from "@/views/widthdraw/userapply";
import roleApply from '@/views/widthdraw/roleapply'
import kong from '@/views/people/kong'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Layout,
    meta: { title: "首页" },
    children: [
      {
        path: "/home",
        component: Home,
      },
    ],
  },
  {
    path: "/userinfo",
    name: "userinfo",
    component: Layout,
    meta: { title: "个人中心" },
    children: [
      {
        path: "/userinfo",
        component: Userinfo,
      },
    ],
  },
  {
    path: "/shop",
    name: "shop",
    component: Layout,
    children: [
      {
        path: "/shop/shopindex",
        component: Shop,
        name: "shopindex",
        meta: { title: "门店管理" },
        children: [
          {
            path: "/shop/shopindex/addshop",
            component: Addshop,
            meta: { title: "新建门店" },
          },
          {
            path: "/shop/shopindex/editshop",
            component: Editshop,
            meta: { title: "编辑门店" },
          },
        ],
      },
    ],
  },
  {
    path: "/service",
    name: "service",
    component: Layout,
    meta: { title: "服务" },
    children: [
      {
        path: "/service/serviceindex",
        component: Service,
      },
    ],
  },
  {
    path: "/bill",
    name: "bill",
    component: Layout,
    meta: { title: "账单" },
    children: [
      {
        path: "/bill/TopUpBill",
        name: "TopUpBill",
        meta: { title: "充值账单" },
        component: TopUpBill,
      },
      {
        path: "/bill/welfareBill",
        name: "welfareBill",
        meta: { title: "福利官账单" },
        component: welfareBill,
      },
      {
        path: "/bill/blanceChange",
        name: "blanceChange",
        meta: { title: "余额变动" },
        component: blanceChange,
      },
    ],
  },
  {
    path: "/order",
    name: "order",
    component: Layout,
    meta: { title: "小程序订单" },
    children: [
      {
        path: "/order/orderindex",
        component: Order,
        children: [
          {
            path: "/order/orderdec",
            component: Orderdec,
            meta: { title: "订单详情" },
          },
        ],
      },
    ],
  },
  {
    path: "/porder",
    name: "porder",
    component: Layout,
    meta: { title: "平台订单" },
    children: [
      {
        path: "/porder/porderindex",
        component: POrder,
      },
    ],
  },
  {
    path: "/people",
    name: "people",
    component: Layout,
    meta: { title: "人员" },
    children: [
      {
        path: "/people/peopleList",
        name: "peopleList",
        meta: { title: "用户列表" },
        component: PeopleList,
        children: [
          {
            path: "/people/peopleList/peopledec",
            name: "peopledec",
            meta: { title: "用户详情" },
            component: Peopledec,
          },
        ],
      },
      {
        path: "/people/welfareList",
        name: "welfareList",
        meta: { title: "福利官列表" },
        component: WelfareList,
        children: [
          {
            path: "/people/welfareList/welfaredec",
            name: "welfaredec",
            meta: { title: "福利官详情" },
            component: Welfaredec,
          },
          {
            path: "/people/welfareList/kong",
            name: "HelloTest",
            meta: { title: "福利官详情" },
            component: kong,
          },
        ],
      },
      {
        path: "/people/contractorList",
        name: "contractorList",
        meta: { title: "承包商列表" },
        component: ContractorList,
        children: [
          {
            path: "/people/contractorList/contractordec",
            name: "contractordec",
            meta: { title: "承包商详情" },
            component: Contractordec,
          },
          {
            path: "/people/contractorList/addcontractor",
            name: "addcontractor",
            meta: { title: "新建承包商" },
            component: Addcontractor,
          },
        ],
      },
      {
        path: "/people/allianceBusinessList",
        name: "allianceBusinessList",
        meta: { title: "加盟商列表" },
        component: AllianceBusinessList,
        children: [
          {
            path: "/people/allianceBusinessList/allianceBusinessdec",
            name: "allianceBusinessdec",
            meta: { title: "加盟商详情" },
            component: AllianceBusinessdec,
          },
          {
            path: "/people/allianceBusinessList/addallianceBusiness",
            name: "addallianceBusiness",
            meta: { title: "新建加盟商" },
            component: AddallianceBusiness,
          },
        ],
      },
      {
        path: "/people/manufacturerList",
        name: "manufacturerList",
        meta: { title: "厂商列表" },
        component: ManufacturerList,
        children: [
          {
            path: "/people/manufacturerList/manufacturerdec",
            name: "manufacturerdec",
            meta: { title: "厂商详情" },
            component: Manufacturerdec,
          },
          {
            path: "/people/manufacturerList/addmanufacturer",
            name: "addmanufacturer",
            meta: { title: "新建厂商" },
            component: Addmanufacturer,
          },
        ],
      },
      {
        path: "/people/cityAgency",
        name: "cityAgency",
        meta: { title: "城市代理" },
        component: CityAgency,
        children: [
          {
            path: "/people/cityAgency/cityAgencydec",
            name: "cityAgencydec",
            meta: { title: "代理详情" },
            component: CityAgencydec,
          },
          {
            path: "/people/cityAgency/addcityAgency",
            name: "addcityAgency",
            meta: { title: "新建代理" },
            component: AddcityAgency,
          },
        ],
      },
    ],
  },
  {
    path: "/promotion",
    name: "promotion",
    component: Layout,
    meta: { title: "促销" },
    children: [
      {
        path: "/promotion/timecard",
        name: "timecard",
        meta: { title: "次卡管理" },
        component: Timecard,
        children: [
          {
            path: "/promotion/timecard/newcard",
            name: "newcard",
            meta: { title: "新建单次卡" },
            component: Newcard,
          },
          {
            path: "/promotion/timecard/yearcardadd",
            name: "yearcardadd",
            meta: { title: "新建多次卡" },
            component: Yearcardadd,
          },
          {
            path: "/promotion/timecard/carddec",
            name: "carddec",
            meta: { title: "洗车卡详情" },
            component: Carddec,
          },
        ],
      },
      {
        path: "/promotion/TikTok",
        name: "TikTok",
        meta: { title: "抖音次卡" },
        component: TikTok,
      },
      {
        path: "/promotion/invite",
        name: "invite",
        meta: { title: "邀请管理" },
        component: Invite,
      },
    ],
  },
  {
    path: "/role",
    name: "role",
    component: Layout,
    meta: { title: "角色管理" },
    children: [
      {
        path: "/role/welfaremanage",
        name: "welfaremanage",
        meta: { title: "福利官管理" },
        component: Welfaremanage,
      },
      {
        path: "/role/contractormanage",
        name: "contractormanage",
        meta: { title: "承包商管理" },
        component: Contractormanage,
      },
      {
        path: "/role/allianceBusinessmanege",
        name: "allianceBusinessmanege",
        meta: { title: "加盟商管理" },
        component: AllianceBusinessmanege,
      },
      {
        path: "/role/cityAgency",
        name: "cityAgencyMgmt",
        meta: { title: "城市代理管理" },
        component: CityAgencymanage,
      },
    ],
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: Layout,
    meta: { title: "提现申请" },
    children: [
      {
        path: "/withdraw/userApply",
        name: "userApply",
        component: userApply,
        meta: { title: "用户申请" },
        children: [
          {
            path: "/withdraw/userApply/withdrawdec",
            name: "userwithdrawdec",
            component: Withdrawdec,
            meta: { title: "提现详情" },
          },
        ]
      },
      {
        path: "/withdraw/roleApply",
        name: "roleApply",
        component: roleApply,
        meta: { title: "角色申请" },
        children: [
          {
            path: "/withdraw/roleApply/withdrawdec",
            name: "rolewithdrawdec",
            component: Withdrawdec,
            meta: { title: "提现详情" },
          },
        ]
      },
    ],
  },
  {
    path: "/other",
    name: "other",
    component: Layout,
    meta: { title: "其他" },
    children: [
      {
        path: "/other/carousel",
        name: "carousel",
        meta: { title: "轮播图管理" },
        component: Carousel,
        children: [
          {
            path: "/other/carousel/addcarousel",
            name: "addcarousel",
            meta: { title: "新建轮播" },
            component: Addcarousel,
          },
          {
            path: "/other/carousel/carouseldec",
            name: "carouseldec",
            meta: { title: "轮播详情" },
            component: Carouseldec,
          },
        ],
      },
      // {
      //   path: "/other/overtimepay",
      //   name: "overtimepay",
      //   meta: { title: "超时费管理" },
      //   component: Overtimepay,
      // },
      {
        path: "/other/applyforcontractor",
        name: "applyforcontractor",
        meta: { title: "承包商申请" },
        component: Applyforcontractor,
      },
      {
        path: "/other/applyforalliancebusiness",
        name: "applyforalliancebusiness",
        meta: { title: "加盟商申请" },
        component: Applyforalliancebusiness,
      },
      {
        path: "/other/Rechargebalance",
        name: "Rechargebalance",
        meta: { title: "充值余额管理" },
        component: Rechargebalance,
        children: [
          {
            path: "/other/Rechargebalance/Rechargebalancedec",
            name: "Rechargebalancedec",
            meta: { title: "充值余额详情" },
            component: Rechargebalancedec,
          },
          {
            path: "/other/Rechargebalance/addRechargebalance",
            name: "addRechargebalance",
            meta: { title: "新建充值金额" },
            component: addRechargebalance,
          },
        ],
      },
      {
        path: "/other/balanceagreement",
        name: "balanceagreement",
        meta: { title: "用户服务协议" },
        component: Balanceagreement,
      },
      {
        path: "/other/privacyagreement",
        name: "privacyagreement",
        meta: { title: "用户隐私协议" },
        component: Privacyagreement,
      },
      {
        path: "/other/carwashnotice",
        name: "carwashnotice",
        meta: { title: "洗车注意事项声明" },
        component: Carwashnotice,
      },
      {
        path: "/other/phone",
        name: "phone",
        meta: { title: "电话客服" },
        component: Phone,
      },
      {
        path: "/other/meituansession",
        name: "meituansession",
        meta: { title: "美团授权" },
        component: MeituanSessioin,
      },
      {
        path: "/other/agreement",
        name: "agreement",
        meta: { title: "平台协议" },
        component: Agreement,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  // 全局配置激活路由的class类名，处与活跃（动态）就会用上这个类名
  linkActiveClass: "active",
});
router.beforeEach((to, from, next) => {
  if (to.path === "/login") return next();
  const tokenStr = window.sessionStorage.getItem("token");
  // 没有token， 强制转到login页面
  if (!tokenStr) return next("/login");
  next();
});

export default router;
