<template>
  <!-- 编辑门店 -->
  <div class="container">
    <div class="mainbox">
      <shop-form ref="shopForm" v-model="form" :radio.sync="radio" :is-edit="true" @radio-change="handleRadioChange">
      </shop-form>
      <div class="btns">
        <div class="cancel" @click="del">删除</div>
        <div class="submit" @click="submitForm">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  shopdec,
  editshop,
  delshop,
} from "@/api/api";
import ShopForm from '@/components/shop/ShopForm.vue';

export default {
  components: {
    ShopForm
  },
  data() {
    return {
      id: this.$route.query.id,
      form: {
        name: "",
        equipment_id: "",
        shopaddress: "",
        shoplon: "",
        shoplat: "",
        shopimg: "",
        province: "",
        city: "",
        area: "",
        codeimg: "",
        sheng: "",
        chuimg: "",
        firm: [], //厂商
        contractor: "", //承包商
        allianceBusiness: "", //加盟商
        controlUsers: [], // 门店控制用户
        vs: {
          rate: 0, //vs概率
          countRange: [0, 0], //vs次数范围
          weekdays: "", //vs星期
          timeRange: ["00:00:00", "00:00:00"], //vs时间范围
          maxDayCount: 0,
        }
      },
      radio: "1",
    };
  },
  created() {
    this.getshopdec();
  },
  methods: {
    // 获取门店详情
    async getshopdec() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await shopdec({ admin_id, store_id: this.id });
        if (res.data.store_type === 1 && res.data.contractor !== 0) {
          this.radio = "1";
          this.form.contractor = res.data.contractor;
        } else if (res.data.store_type === 2 && res.data.franchisee !== 0) {
          this.radio = "2";
          this.form.allianceBusiness = res.data.franchisee;
        }
        this.form.name = res.data.store_name;
        this.form.equipment_id = res.data.equipment_id;
        this.form.shopimg = res.data.store_img;
        this.form.province = res.data.store_sheng;
        this.form.city = res.data.store_shi;
        this.form.area = res.data.store_qu;
        this.form.shopaddress = res.data.store_address;
        this.form.shoplon = res.data.store_address_lon;
        this.form.shoplat = res.data.store_address_lat;
        this.form.codeimg = res.data.equipment_code_img;
        this.form.chuimg = res.data.open_door_code_img;
        this.form.sheng = res.data.store_sheng;
        this.form.vs.rate = res.data.vs.vsRate;
        let countRange = res.data.vs.vsCountRange.indexOf("-") >= 0 ? res.data.vs.vsCountRange.split("-") : [res.data.vs.vsCountRange, res.data.vs.vsCountRange];
        this.form.vs.countRange = countRange.map((value) => { return Number(value) });
        let weekdays = res.data.vs.vsWeekdays.split("");
        this.form.vs.weekdays = [];
        for (let i = 0; i < weekdays.length; i++) {
          if (weekdays[i] === "1") {
            this.form.vs.weekdays.push(i + 1);
          }
        }
        this.form.vs.timeRange = [res.data.vs.vsStartTime, res.data.vs.vsEndTime];
        this.form.vs.maxDayCount = res.data.vs.vsMaxCount;
        if (res.data.manufacturerFind && res.data.manufacturerFind[0].manufacturer_id !== 0) {
          this.form.firm = res.data.manufacturerFind;
        }
        if (res.data.controlUserFind) {
          this.form.controlUsers = res.data.controlUserFind.map(user => ({
            user_id: user.user_id,
            nickname: user.nickname || '',
            allowed_commands: Array.isArray(user.allowed_commands)
              ? user.allowed_commands
              : (user.allowed_commands ? user.allowed_commands.split(',') : [])
          }));
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 删除门店
    async del() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        await delshop({ admin_id, store_id: this.id });
        this.$message({
          type: "success",
          message: "删除成功！",
        });
        setTimeout(() => {
          this.$router.back();
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    },
    handleRadioChange(value) {
      this.radio = value;
    },
    async submitForm() {
      const valid = await this.$refs.shopForm?.validate?.();
      if (valid) {
        try {
          const admin_id = sessionStorage.getItem("admin_id");
          // 处理提交的厂商数据格式
          const firmData = this.form.firm.map((item) => ({
            id: item.manufacturer_id,
            ratio: item.commission_ceiling,
          }));

          // 处理控制用户数据格式
          const controlUsersData = this.form.controlUsers.map(user => ({
            user_id: user.user_id,
            nickname: user.nickname || '',
            allowed_commands: user.allowed_commands.join(',')
          }));

          if (this.radio === "1") {
            await editshop({
              admin_id,
              store_id: this.id,
              store_type: this.radio,
              store_name: this.form.name,
              equipment_id: this.form.equipment_id,
              store_img: this.form.shopimg,
              store_sheng: this.form.province,
              store_shi: this.form.city,
              store_qu: this.form.area,
              store_address: this.form.shopaddress,
              store_lon: this.form.shoplon,
              store_lat: this.form.shoplat,
              store_img_code: this.form.codeimg,
              joining_id: this.form.contractor,
              manufacturer: JSON.stringify(firmData),
              control_users: JSON.stringify(controlUsersData),
              vsRate: this.form.vs.rate,
              vsCountRange: this.form.vs.countRange[0] === this.form.vs.countRange[1] ? this.form.vs.countRange[0] : this.form.vs.countRange[0] + "-" + this.form.vs.countRange[1],
              vsWeekdays: this.form.vs.weekdays,
              vsStartTime: this.form.vs.timeRange[0],
              vsEndTime: this.form.vs.timeRange[1],
              vsMaxCount: this.form.vs.maxDayCount,
            });
          } else if (this.radio === "2") {
            await editshop({
              admin_id,
              store_id: this.id,
              store_type: this.radio,
              store_name: this.form.name,
              store_img: this.form.shopimg,
              store_sheng: this.form.province,
              store_shi: this.form.city,
              store_qu: this.form.area,
              store_address: this.form.shopaddress,
              store_img_code: this.form.codeimg,
              joining_id: this.form.allianceBusiness,
              manufacturer: JSON.stringify(firmData),
              control_users: JSON.stringify(controlUsersData),
            });
          }
          this.$message({
            type: "success",
            message: "保存成功！",
          });
          setTimeout(() => {
            this.$router.back();
          }, 1000);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log("error submit!!");
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  padding: 20px;
  box-sizing: border-box;

  .btns {
    margin-top: 32px;
    margin-left: 20px;
    display: flex;
    justify-content: center;

    .cancel {
      width: 92px;
      height: 36px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #ff5733;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ff5733;
      line-height: 36px;
      text-align: center;
      border-radius: 8px;
      cursor: pointer;
    }

    .submit {
      width: 92px;
      height: 36px;
      background: #0073ff;
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      margin-left: 12px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}
</style>
