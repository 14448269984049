<template>
  <!-- 新建门店 -->
  <div class="container">
    <div class="mainbox">
      <shop-form ref="shopForm" v-model="form" :radio.sync="radio" :is-edit="false" @radio-change="handleRadioChange">
      </shop-form>
      <div class="btns">
        <div class="cancel" @click="$router.back()">取消</div>
        <div class="submit" @click="submitForm">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  addshop,
} from "@/api/api";
import ShopForm from '@/components/shop/ShopForm.vue';

export default {
  components: {
    ShopForm
  },
  data() {
    return {
      form: {
        name: "",
        shopaddress: "",
        shoplon: "",
        shoplat: "",
        equipment_id: "",
        shopimg: "",
        province: "",
        city: "",
        area: "",
        codeimg: "",
        firm: [
          {
            id: "",
            ratio: "", // 返佣上限
          },
        ], // 厂商
        contractor: "", // 承包商
        allianceBusiness: "", // 加盟商
        controlUsers: [], // 门店控制用户
        vs: {
          rate: 0, // vs概率
          countRange: [0, 0], // vs次数范围
          weekdays: [], // vs星期
          timeRange: ["00:00:00", "00:00:00"], // vs时间范围
          maxDayCount: 0,
        }
      },
      radio: "1",
    };
  },
  methods: {
    handleRadioChange(value) {
      this.radio = value;
      if (value === "1") {
        this.form.allianceBusiness = "";
      } else {
        this.form.contractor = "";
      }
    },
    async submitForm() {
      const valid = await this.$refs.shopForm?.validate?.();
      if (valid) {
        try {
          const admin_id = sessionStorage.getItem("admin_id");

          // 处理控制用户数据格式
          const controlUsersData = this.form.controlUsers.map(user => ({
            user_id: user.user_id,
            nickname: user.nickname || '',
            allowed_commands: user.allowed_commands.join(',')
          }));

          if (this.radio === "1") {
            await addshop({
              admin_id,
              store_type: this.radio,
              store_name: this.form.name,
              equipment_id: this.form.equipment_id,
              store_img: this.form.shopimg,
              store_sheng: this.form.province,
              store_shi: this.form.city,
              store_qu: this.form.area,
              store_address: this.form.shopaddress,
              store_lon: this.form.shoplon,
              store_lat: this.form.shoplat,
              joining_id: this.form.contractor,
              manufacturer: JSON.stringify(this.form.firm),
              control_users: JSON.stringify(controlUsersData),
            });
          } else if (this.radio === "2") {
            await addshop({
              admin_id,
              store_type: this.radio,
              store_name: this.form.name,
              store_img: this.form.shopimg,
              store_sheng: this.form.province,
              store_shi: this.form.city,
              store_qu: this.form.area,
              store_address: this.form.shopaddress,
              joining_id: this.form.allianceBusiness,
              manufacturer: JSON.stringify(this.form.firm),
              control_users: JSON.stringify(controlUsersData),
            });
          } else {
            this.$message({
              type: "error",
              message: "保存失败！门店类型未选择！",
            });
            return false;
          }

          this.$message({
            type: "success",
            message: "保存成功！",
          });
          setTimeout(() => {
            this.$router.back();
          }, 1000);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log("error submit!!");
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  padding: 20px;
  box-sizing: border-box;

  .btns {
    margin-top: 32px;
    margin-left: 20px;
    display: flex;
    justify-content: center;

    .cancel {
      width: 92px;
      height: 36px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #dddddd;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #dddddd;
      line-height: 36px;
      text-align: center;
      border-radius: 8px;
      cursor: pointer;
    }

    .submit {
      width: 92px;
      height: 36px;
      background: #0073ff;
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      margin-left: 12px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}
</style>
